import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/query/react";
import Message from "@src/utility/notifications/Message";
import { PermissionResult } from "../../types/auth";
import { loggedOut } from "../slices/authStore";

//https://stackoverflow.com/questions/72613859/refresh-token-with-redux-toolkit-query
//TODO: https://redux-toolkit.js.org/rtk-query/usage/customizing-queries
//TODO: implement refresh token imp
const baseQueryWithAuth = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_BASE_URL,
  prepareHeaders: async (headers) => {
    const accessToken = localStorage.getItem("accessToken");

    if (accessToken) {
      headers.set("Authorization", `Bearer ${accessToken}`);
    }

    headers.set("Content-Type", "application/json");
    headers.set("Accept", "application/json");
    headers.set("Cache-Control", "no-cache");
    headers.set("Pragma", "no-cache");
    // headers.set("Expires", "0");

    return headers;
  },
});

const baseQueryWithReauth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  let result = await baseQueryWithAuth(args, api, extraOptions);
  if (result.error && result.error.status === 401) {
    // try to get a new token
    const refreshToken = localStorage.getItem("refreshToken");
    const refreshResult = await baseQueryWithAuth(
      {
        url: `/TokenAuth/RefreshToken?refreshToken=${refreshToken}`,
        method: "POST",
      },
      api,
      extraOptions
    );
    if (refreshResult.data) {
      const refeshTokenResult = refreshResult.data as any;
      // store the new token
      localStorage.setItem("accessToken", refeshTokenResult.data.accessToken);
      localStorage.setItem("refreshToken", refeshTokenResult.data.refreshToken);

      // retry the initial query
      result = await baseQueryWithAuth(args, api, extraOptions);
    } else {
      api.dispatch(loggedOut());
    }
  }
  if (result.error && result.error.status === 500) {
    const error = result?.error?.data as any;

    if (error?.exception) {
      Message(error?.exception, "", "error");
    }
  }
  return result;
};

export const apiGenerator = createApi({
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    getUserPermissions: builder.query<PermissionResult, undefined>({
      query: (data) => ({
        url: `/Services/App/User/GetpermissionsForCurrentUser`,
        method: "GET",
      }),
    }),
  }),
});

export const { useLazyGetUserPermissionsQuery } = apiGenerator;
