import { combineReducers } from "redux";
import storage from "redux-persist/lib/storage";
import { apiGenerator } from "./api/apiGenerator";
import authStore from "./slices/authStore";
import layout from "./layout";
import navbar from "./navbar";
import users from "@src/views/administration/users/store";
import packorders from "@src/views/packorder/store";
import machinestock from "@src/views/machinestock/store";

const rootPersistConfig = {
  key: "root",
  storage,
  keyPrefix: "redux-",
  blacklist: ["api"],
};

const rootReducer = combineReducers({
  [apiGenerator.reducerPath]: apiGenerator.reducer,
  auth: authStore,
  navbar,
  layout,
  users,
  packorders,
  machinestock,
});

export { rootPersistConfig, rootReducer };
