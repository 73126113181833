import { persistor, RootState } from "../store";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LoginResult } from "../../types/auth";

const slice = createSlice({
  name: "auth",
  initialState: { userId: null, isLoggedIn: null, permissions: null } as {
    userId: null | string;
    isLoggedIn: null | boolean;
    permissions: null | string[];
    userData: LoginResult["user"] | null;
  },
  reducers: {
    setCredentials: (
      state,
      { payload: { data } }: PayloadAction<{ data: LoginResult }>
    ) => {
      state.isLoggedIn = data.accessToken !== undefined;
      state.userId = data.userId.toString();
      state.userData = data.user;
    },
    loggedOut: (state) => {
      state.isLoggedIn = false;
      state.userId = "";
      state.userData = null;
      state.permissions = null;
      localStorage.removeItem("accessToken");
      localStorage.removeItem("refreshToken");
      localStorage.removeItem("redux-root");
      localStorage.removeItem("userData");
    },
    setPermissions: (
      state,
      { payload: { permissions } }: PayloadAction<{ permissions: string[] }>
    ) => {
      state.permissions = permissions;
    },
  },
});

export const { setCredentials, loggedOut, setPermissions } = slice.actions;
export const selectCurrentUser = (state: RootState) => state.authStore.userId;
export const isAuthenticated = (state: RootState) => state.authStore.isLoggedIn;

export const StoreState = (state: RootState) => state;

export default slice.reducer;
