import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const getData = createAsyncThunk(
  "machinestock/getData",
  async (params) => {
    const response = await axios.get("/v1/MachineStock/GetAll", {
      params,
    });

    return {
      params,
      data: response.data.data.items,
      total: response.data.data.totalCount,
    };
  }
);

export const deleteMachinestock = createAsyncThunk(
  "machinestock/Delete",
  async (id, { dispatch, getState, rejectWithValue }) => {
    try {
      await axios.delete(`/v1/MachineStock/${id}`);
      await dispatch(getData(getState().machinestock.params));
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const addMachinestock = createAsyncThunk(
  "machinestock/addMachinestock",
  async (machinestock, { dispatch, getState, rejectWithValue }) => {
    try {
      await axios.post(`/v1/MachineStock`, machinestock);
      await dispatch(getData(getState().machinestock.params));
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const machinestockSlice = createSlice({
  name: "appMachinestock",
  initialState: {
    data: [],
    total: 0,
    params: {},
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getData.fulfilled, (state, action) => {
      state.data = action.payload.data;
      state.params = action.payload.params;
      state.total = action.payload.total;
    });
  },
});

export default machinestockSlice.reducer;
