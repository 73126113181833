import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import axios from "axios";

export const getData = createAsyncThunk(
  "appPackorder/getData",
  async (params) => {
    const response = await axios.get("/v1/PackOrder/GetAll", {
      params,
    });
    return {
      params,
      data: response.data.data.items,
      total: response.data.data.totalCount,
    };
  }
);

export const downloadMachineFile = createAsyncThunk(
  "appPackorder/Generate",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.get(`/v1/PackOrder/Generate/${id}`);
      window.open(
        `${process.env.REACT_APP_API_BASE_URL}/v1/PackOrder/Download/${response.data.data.fileToken}`,
        "_blank"
      );
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const downloadSummary = createAsyncThunk(
  "appPackorder/DownloadSummary",
  async (packOrderId, { rejectWithValue }) => {
    try {
      const response = await axios.get(`/v1/PackOrder/Generate/${id}`);
      window.open(
        `${process.env.REACT_APP_API_BASE_URL}/v1/download-summary/${packOrderId}`,
        "_blank"
      );
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const packorderSlice = createSlice({
  name: "Packorders",
  initialState: {
    data: [],
    total: 1,
    params: {},
    isDownloadingPackOrder: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data;
        state.params = action.payload.params;
        state.total = action.payload.total;
      })
      .addCase(downloadMachineFile.pending, (state, action) => {
        state.isDownloadingPackOrder = [
          ...state.isDownloadingPackOrder,
          action.meta.arg,
        ];
      })
      .addCase(downloadMachineFile.fulfilled, (state, action) => {
        const machineGeneratingIds = JSON.parse(
          JSON.stringify(state.isDownloadingPackOrder)
        );
        const index = machineGeneratingIds.indexOf(action.meta.arg);
        if (index !== -1) {
          machineGeneratingIds.splice(index, 1);
          state.isDownloadingPackOrder = machineGeneratingIds;
        }
      });
  },
});

export default packorderSlice.reducer;
